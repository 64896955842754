@import '../styles/constants.scss';

.navbar {
  background-color: $general-white;
  min-height: $headerHeight;
  box-shadow: 1px 0px 5px #000000;
  z-index: 99;
}

.navbar-brand {
  // height: 50px;
  padding: 0;
}

.navbar-brand > img {
  height: 50px;
  transition: 0.2s ease;

  &:hover {
    filter: saturate(0);
  }
}

.isActive {
  color: #000000 !important;
  font-weight: 500;
}
