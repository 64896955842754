@import '../styles/constants.scss';

.item-img {
  width: 100%;
  height: 280px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.wifi-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50px;
  top: 20px;
}
.home-item-flex {
  color: $general-black;
}
.home-item-flex:hover {
  text-decoration: none;
  background-color: #f2f2f2;
  g {
    fill: $primary-color;
  }
  svg {
    fill: $primary-color;
  }
}

.item-display {
  position: relative;
  height: 100%;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover a {
    text-decoration: none;
  }

  &:hover {
    * > h3 {
      color: $primary-color;
    }

    & > div.item-button {
      background-color: lighten($general-black, 15);
      color: $general-white;
    }
    .mini-picture {
      background-color: #ddd;
    }
  }
}

.item-sqm {
  font-size: 24px;
}

.item-button {
  background-color: $general-black;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $general-white;
}

.item-title {
  font-size: 26px;
}

@media (max-width: 768px) {
  .item-img {
    img {
      width: 90%;
      height: 100%;
      object-fit: contain;
    }
  }
}

$miniPictureSize: 120px;
.mini-picture {
  position: absolute;
  height: $miniPictureSize;
  width: $miniPictureSize;
  border-radius: $miniPictureSize/2;
  background-color: #eee;
  top: 30px;

  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
