@import '../styles/constants.scss';

.footer-box {
  display: flex;
  flex-direction: center;
  margin-top: 50px;
  justify-content: center;
  z-index: 50;
}

.footer-nav {
  width: 100%;
  height: $footerHeight;
  background-color: $primary-color;
  display: flex;
  flex-direction: row;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
}

.footer-list {
  span {
    font-weight: 500;
    color: #f5f5f5;
    cursor: default;
  }

  li {
    a {
      color: #d5d5d5;

      &:hover {
        color: #f5f5f5;
      }
    }
  }
}

.footer-logo-container {
  max-width: 20%;

  .footer-logo {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 140px;
    max-width: 100%;

    img {
      width: 100%;
    }
  }
}

@media (max-width: 480px) {
  .footer-content {
    width: 95%;
  }
}
