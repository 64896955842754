@import '../../styles/constants.scss';
.store {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 30px;
}

.add-to-cart {
  width: 300px;
  height: 40px;
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $primary-color;
  color: #fefefe;
  transition: 0.2s background-color;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    background-color: transparent;
    color: $primary-color;
    font-weight: 500;
  }
}

.quantity-display {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-bottom: 10px;

  div {
    width: 40px;
    height: 40px;
    border: 1px solid black;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border-color: $primary-color;
      color: $primary-color;
    }
    &:nth-child(1) {
      border-radius: 5px 0 0 5px;
      border-right: none;
    }
    &:nth-child(3) {
      border-radius: 0 5px 5px 0;
      border-left: none;
    }

    input {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      -webkit-appearance: none;
      background-color: transparent;
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-vendor {
  padding: 20px;
  border: 1px solid #ddd;
  background-color: white;
  margin: 0 auto;
  margin-top: 120px;
  width: 1200px;
  max-width: 94%;
  height: calc(100vh - 240px);
  z-index: 99;
  display: flex;
  flex-direction: column;
  row-gap: 0px;

  & > div {
    display: flex;
    height: 60px;
    width: 100%;
    margin: 20px auto;
    background-color: #fafafa;
    flex-direction: row;
  }

  h2 {
    text-align: center;
  }

  hr {
    width: 100%;
  }

  button {
    margin-left: auto;
    margin-right: 2%;
    margin-top: auto;
    width: 100px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    color: $primary-color;

    &:hover {
      color: black;
    }
  }
}

.vendor-info {
  display: flex;
  flex-direction: row;
  width: calc(100% - 160px);
  div:nth-child(1) {
    padding: 0 10px;
    width: 200px;
    display: flex;
    align-items: center;
    border-right: 1px solid #ccc;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
  div:nth-child(2) {
    width: calc(100% - 200px);
    padding-left: 20px;
    display: flex;
    align-items: center;
    border-right: 1px solid #ccc;
    span {
      font-size: 18px;
    }
  }
}
.vendor-buy {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 120px;
  height: 40px;
  border: 1px solid transparent;
  background-color: $primary-color;
  color: white;

  &:hover {
    border: 1px solid $primary-color;
    color: $primary-color;
    background-color: rgba($primary-color, 0.2);
    text-decoration: none;
  }
}

@media (max-width: 900px) {
  .modal-vendor {
    height: calc(100vh - 240px);
  }

  .vendor-info {
    width: calc(100% - 140px);
    div:nth-child(1) {
      padding: 0 10px;
      width: 25%;
    }
    div:nth-child(2) {
      width: 75%;
      padding-left: 20px;
      span {
        font-size: 2vw;
      }
    }
  }
  .vendor-buy {
    width: 100px;
    height: 30px;
  }
}
@media (max-width: 900px) {
  .vendor-info {
    width: calc(100% - 80px);
    div:nth-child(1) {
      padding: 0 10px;
      width: 25%;
    }
    div:nth-child(2) {
      width: 75%;
      padding-left: 20px;
      span {
        font-size: 14px;
      }
    }
  }
  .vendor-buy {
    width: 80px;
    height: 100%;
  }

  .modal-vendor {
    padding: 10px;
    max-width: 94%;
    row-gap: 0px;

    & > div {
      display: flex;
      height: 40px;
      width: 96%;
      padding: 0 5px;
    }
  }
}

@media (max-width: 600px) {
  @media (max-width: 900px) {
    .vendor-info {
      div:nth-child(1) {
        display: none;
      }
      div:nth-child(2) {
        width: 100%;
      }
    }
  }
}
