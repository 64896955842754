@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$primary-color: #039fdc;
$secondary-color: #0126b3;
$general-white: #ffffff;
$general-black: #1d1d1f;

$neutral-grey: #c1c1c1;

$headerHeight: 70px;
$footerHeight: 260px;
$bodyHeight: calc(100vh - 70px - 260px);
