@font-face {
  font-family: Leman;
  src: local('Leman'), url(./fonts/Leman.otf) format('otf');
  src: local('Leman'), url(./fonts/Leman.ttf) format('ttf');
}

html {
  scroll-behavior: smooth;
  font-family: "Leman";
}

p, span, h1, h2, h3, h4, h5, h6, small, a, li, button, th, tr {
  letter-spacing: 2px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}