@import '../styles/constants.scss';

.single-content-wrapper {
  position: relative;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.single-image-wrapper {
  position: relative;
  width: 50%;
  height: calc(100vh - 70px);
}

.item-wifi-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50px;
  top: 50px;
  z-index: 2;
}

.single-img-container {
  width: 100%;
  height: 500px;
  text-align: center;

  img {
    width: 90%;
    max-height: 100%;
    object-fit: contain;
  }
}

.product-slider {
  height: 100%;

  div {
    height: 100%;
    position: relative;

    .control-dots {
      height: 20px;
      bottom: 20px;

      li {
        background: $primary-color !important;
        outline: none;
      }
    }
  }

  ul {
    height: 100%;
    li {
      height: 100%;
      background-color: #f2f2f2 !important;
    }
  }
}

.single-item-slide {
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;

  img {
    margin: auto;
    max-width: 80% !important;
    max-height: 80%;
    object-fit: contain;
  }
}

.single-information-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 10px;
  padding: 20px 40px;
  text-align: center;

  h2 {
    font-size: 46px;
  }

  hr {
    margin: 0 auto;
    padding: 0;
    width: 150px;
    border-bottom: 1px solid $general-black;
  }

  p {
    text-align: left;
  }

  ul {
    list-style: circle;
    li {
      text-align: left;
      font-size: 16px;
    }
  }
}

.single-product-specifications {
  width: 50%;
  padding: 0 30px;

  h3 {
    max-width: 600px;
    margin: 0 auto;
  }

  table {
    width: 100%;
    max-width: 600px;
    margin: auto;
    tr {
      td:nth-child(1) {
        font-weight: bold;
      }
    }

    @for $i from 1 through 99 {
      tr:nth-child(#{$i}) {
        @if $i % 2 == 0 {
          background-color: #f0f0f0;
        }
      }
    }
  }
}

.single-usage-areas {
  width: 50%;
  max-width: 700px;
  padding-top: 20px;
  padding: 0 30px;

  ol {
    list-style: upper-alpha;
    padding: 0;

    li {
      margin-bottom: 5px;
      text-align: left;
    }
  }
}

@media (max-width: 1024px) {
  .single-image-wrapper {
    width: 100%;
  }

  .single-information-wrapper {
    width: 100%;
  }

  .single-product-specifications {
    width: 100%;
    padding: 0 30px;

    h3 {
      max-width: 600px;
      margin: 0 auto;
    }

    table {
      width: 100%;
      max-width: 600px;
      margin: auto;
    }
  }

  .single-usage-areas {
    width: 100%;
    margin: auto;
    margin-top: 50px;
    max-width: 700px;
    padding-top: 20px;
    padding: 0 30px;
  }
}
@media (max-width: 768px) {
}
@media (max-width: 480px) {
  ul {
    list-style: circle;
    padding: 0;
    li {
      text-align: left;
      margin-bottom: 10px;
      font-size: 16px;
    }
  }

  .single-product-specifications {
    width: 100%;
    padding: 0;

    h3 {
      text-align: center;
      margin-bottom: 10px;
    }

    table {
      width: 100%;
      max-width: 600px;
      margin: auto;
      tr {
        td {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}
